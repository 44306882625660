<template>
  <div class="p-4">
    <div v-if="isLoading" class="grid place-content-center z-50 w-full h-full">
      <svg class="animate-spin" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="13.25" cy="12.75" r="10.75" stroke="#F9CFE1" stroke-width="4"/>
        <path d="M24 12.75C24 6.81294 19.1871 2 13.25 2C7.31294 2 2.5 6.81294 2.5 12.75C2.5 18.6871 7.31294 23.5 13.25 23.5" stroke="#F42272" stroke-width="4" stroke-linecap="round"/>
      </svg>
    </div>
    <div v-else class="flex flex-col">
      <div class="m-2 text-xs text-gray-500 flex flex-col md:flex-row">
        <div class="flex-auto w-2/5"><a target="_blank" href="https://help.particleone.com/video-adjusting-the-parallel-coordinates-plot/" class="text-pink-600 underline hover:no-underline">Watch this video</a> for instructions on how to use the Parallel-Coordinates Plot</div>
        <hr class="my-5 mt-7 md:hidden">
        <div class="flex-auto w-3/5 md:border-l md:pl-5 md:ml-5">
          <a 
            target="_blank"
            href="https://help.particleone.com/reference-guides/learn-more/"
            class="text-pink-600 underline hover:no-underline">Learn more
          </a>
          about the mitigation options
        </div>
      </div>
      <div class="iframe-container">
        <iframe border="0" loading="lazy" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" :srcdoc="parallelCoordinatePlot">
        </iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DashboardSpaceSimulations',
  computed: {
    isLoading() {
      return this.parallelCoordinatePlot == null;
    },
    ...mapGetters(['parallelCoordinatePlot'])
  },
  async mounted() {
    await this.getParallelCoordinatePlot(this.$route.params.spaceId);
  },
  methods: {
    ...mapActions(['getParallelCoordinatePlot'])
  }
};
</script>

<style scoped>
.iframe-container {
  position: relative;
  overflow: hidden;
  padding-top: 50%;
}
.iframe-container iframe {
  position: absolute;
  top: 0;
  border: 0;
  height: 100%;
  width: 100%;
}
</style>